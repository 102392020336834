/* eslint linebreak-style: ["error", "unix"] */
import { ClassNameMap, CombinedClassKey } from 'notistack';
import { createMuiTheme, Theme, SimplePaletteColorOptions } from '@material-ui/core';
import { applyOnEnvironment, Environments, extractEnv } from 'utils/common';

export const ANJB_CONSTANTS = {
  contact: {
    email: 'direccao@anjb.pt',
    phoneNumber: '',
    googleMapsUrl: 'https://maps.google.com/maps?q=Rua%20Padre%20Am%C3%A9ric%20600-548%20Lisboa&t=&z=15&ie=UTF8&iwloc=&output=embed',
    street: 'Rua Padre Américo, nº 4 B - 1º',
    postalCode: '1600-548',
    city: 'Lisboa',
  },
  social: {
    facebook: 'https://www.facebook.com/anjb.pt/',
    instagram: 'https://www.instagram.com/anjb.pt/',
    youtube: 'https://www.youtube.com/channel/UCVhQMRKGPT7fOql-lrrT4zg',
  },
  partners: [
    {
      image: '/images/partners/fpb.png',
      alt: 'fpb',
      link: 'http://www.fpb.pt/',
    },
    {
      image: '/images/partners/LigaBetclic.png',
      alt: 'LigaBetclic Masculina',
      link: 'http://www.fpb.pt/',
    },
    {
      image: '/images/partners/LigaBetclicFem.png',
      alt: 'LigaBetclic Feminina',
      link: 'http://www.fpb.pt/',
    },
    {
      image: '/images/partners/proliga.png',
      alt: 'proliga',
      link: 'http://www.fpb.pt/',
    },
    {
      image: '/images/partners/ipjb_alt.png',
      alt: 'idesporto',
      link: 'http://www.idesporto.pt/',
    },
    {
      image: '/images/partners/fiba.png',
      alt: 'fiba basketball',
      link: 'http://www.fiba.basketball/',
    },
    {
      image: '/images/partners/euroleague.png',
      alt: 'euro league',
      link: 'https://www.euroleague.net/',
    },
  ],
  partnerships: [
    {
      image: '/images/partners/dhika.png',
      alt: 'dhika',
      link: 'https://www.dhika.pt',
    },
    {
      image: '/images/partners/logo_osteoFisio.jpg',
      alt: 'OSTEOFISIO lab',
      link: 'https://osteofisiolab.pt/',
    },
    {
      image: '/images/partners/logo_acp.jpg',
      alt: 'ACP',
      link: 'https://www.acp.pt/',
    },
    {
      image: '/images/partners/logo_afarmaciav2.png',
      alt: 'A Farmácia Online',
      link: 'https://www.afarmaciaonline.pt/',
    },
    {
      image: '/images/partners/logo_clinicaDentariaLuz.png',
      alt: 'Clinica Dentária da Luz',
      link: 'https://www.cmdluz.pt/',
    },
    {
      image: '/images/partners/logo_clinicaDoTempov2.png',
      alt: 'Clinica do Tempo',
      link: 'https://clinicadotempo.com/',
    },
    {
      image: '/images/partners/prozis.jpg',
      alt: 'prozis',
      link: 'https://www.prozis.com/pt/pt',
    },
    {
      image: '/images/partners/logo_smileUp.jpg',
      alt: 'Smile Up',
      link: 'https://smileup.pt/',
    },
    {
      image: '/images/partners/logo_skyDivev2.jpg',
      alt: 'Skydive Portugal',
      link: 'https://skydiveportugal.pt/',
    },
  ],
};

export const APP_CONFIG = {
  app: {
    name: extractEnv('APP_NAME') || 'App',
    environment: extractEnv('NODE_ENV') || 'local',
    api: applyOnEnvironment( // @ts-ignore
      () => extractEnv('API_URI_LOCAL'),
      () => extractEnv('API_URI_STAGING'),
      () => extractEnv('API_URI_PRODUCTION'),
    ),
    options: {
      logUserOutOnAuthError:
        extractEnv('APP_OPTIONS_LOG_USER_OUT_ON_AUTH_ERROR') || false,
      showErrorPageByDefault:
        extractEnv('APP_OPTIONS_SHOW_ERROR_PAGE_ON_GRAPHQL_ERROR') || true,
    },
    password: {
      minimumLength: parseInt(extractEnv('APP_PASSWORD_MINIMUM_LENGTH') as any || 8, 10),
    },
  },
  http: {
    bucketRequestUri: extractEnv('HTTP_BUCKET_REQUEST_URI') || 'access-resource',
    customHeaders: {
      accessToken:
        extractEnv('HTTP_CUSTOM_HEADERS_ACCESS_TOKEN') || 'x-access-token',
    },
  },
  graphql: {
    uri: applyOnEnvironment(
      () => `${extractEnv('API_URI_LOCAL')}/graphql`,
      () => `${extractEnv('API_URI_STAGING')}/graphql`,
      () => `${extractEnv('API_URI_PRODUCTION')}/graphql`,
    ),
  },
  public: {
    basePath: extractEnv('PUBLIC_FOLDER_PATH') || 'http://localhost:8080/public',
    imageUploads: `${extractEnv('PUBLIC_FOLDER_PATH') || 'http://localhost:8080/public'}/uploads/image-uploads`,
  },
};

// MATERIAL UI

export const primaryColors: SimplePaletteColorOptions = {
  main: '#cc4a33',
  light: '#ff7b5e',
  dark: '#94140a',
  contrastText: '#333333',
};

export const secondaryColors: SimplePaletteColorOptions = {
  main: '#0c0c0c',
  light: '#5c5c5c',
  dark: '#0c0c0c',
  contrastText: '#FFF',
};

export const ERROR_COLOR = '#ff5252';

export const BACKGROUND_COLOR = '#F2F2F2';

export const getPrimaryTheme = (): Theme => createMuiTheme({
  palette: {
    primary: primaryColors,
    secondary: secondaryColors,
    error: secondaryColors,
  },
  typography: {
    fontFamily: [
      'Roboto Condensed',
    ].join(','),
    fontSize: 15,
  },
  overrides: {
    MuiInput: {
      // Name of the styleSheet
      underline: {
        '&:hover:not($disabled):before': { // @ts-ignore
          backgroundColor: primaryColors[500], // @ts-ignore
          borderBottom: secondaryColors[500],
          height: 1,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        color: secondaryColors.main,
        '& $notchedOutline': {
          borderWidth: 2,
          borderColor: secondaryColors.main,
        },
        '&:hover $notchedOutline': {
          borderWidth: 2,
          borderColor: secondaryColors.main,
        },
        '&$focused $notchedOutline': {
          borderWidth: 2,
          borderColor: secondaryColors.main,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
        },
      },
    },
  },
});

export const snackbarClasses: Partial<ClassNameMap<CombinedClassKey>> = {
  variantSuccess: 'snackbarSuccess',
  variantInfo: 'snackbarInfo',
};

// MODAL QUERY PARAMS

export type ModalId = string | number | null

export const ModalQueryParams = {
  // NAVBAR
  ABOUT: 'modal:sobre_a_anjb',
  SIGN_IN: 'modal:iniciar_sessao',
  SIGN_UP: 'modal:criar_conta',
  SIGN_UP_SUCCESS: 'modal:conta_criada_com_sucesso',
  RECOVER_PASSWORD: 'modal:recuperar_acesso',
  RESET_PASSWORD: (code: string | null = '') => `modal:redefinir_senha&code=${code}`,
  DEFINE_ACCESS: (code: string | null = '') => `modal:definir_senha&code=${code}`,
  SEARCH: 'modal:pesquisar',

  // PUBLICATIONS
  READ_PUBLICATION: (id: ModalId) => `modal:ver_publicacao&id=${id}`,

  // STORE
  VIEW_PRODUCT: (id: ModalId) => `modal:ver_produto&id=${id}`,

  // SUBSCRIPTIONS
  SUBSCRIBER_PERKS: 'modal:ver_vantagens_de_adesao',

  // MOBILE only
  NAVBAR_MOBILE_MENU: 'modal:menu_principal_responsivo',

  // BACKOFFICE

  // Publications
  CREATE_NEW_PUBLICATION: 'modal:criar_publicacao',
  EDIT_PUBLICATION: (id: ModalId) => `modal:editar_publicacao&id=${id}`,
  // Publication Categories
  CREATE_NEW_PUBLICATION_CATEGORY: 'modal:criar_categoria_de_publicacao',
  EDIT_PUBLICATION_CATEGORY: (id: ModalId) => `modal:editar_categoria_de_publicacao&id=${id}`,
  // Pages
  READ_PAGE: (id: ModalId) => `modal:ver_pagina&id=${id}`,
  CREATE_NEW_PAGE: 'modal:criar_pagina',
  EDIT_PAGE: (id: ModalId) => `modal:editar_pagina&id=${id}`,
  // Newsletter
  SEND_NEWSLETTER: 'modal:enviar_newsletter',
  // Users
  VIEW_USER: (id: ModalId) => `modal:ver_utilizador&id=${id}`,
  CREATE_USER: 'modal:criar_utilizador',
  EDIT_USER: (id: ModalId) => `modal:editar_utilizador&id=${id}`,
  // Store
  // Product Orders
  VIEW_PRODUCT_ORDER: (id: ModalId) => `modal:ver_encomenda&id=${id}`,
  // Product Variants
  VIEW_PRODUCT_VARIANT: (id: ModalId) => `modal:ver_variante_de_produto&id=${id}`,
  CREATE_NEW_PRODUCT_VARIANT: 'modal:criar_variante_de_produto',
  EDIT_PRODUCT_VARIANT: (id: ModalId) => `modal:editar_variante_de_produto&id=${id}`,
  // Products
  VIEW_PRODUCT_BACKOFFICE: (id: ModalId) => `modal:ver_produto_backoffice&id=${id}`,
  CREATE_NEW_PRODUCT: 'modal:criar_produto',
  EDIT_PRODUCT: (id: ModalId) => `modal:editar_produto&id=${id}`,
  // Product Filter
  // VIEW_PRODUCT_FILTER_CATEGORY: (id: ModalId) => `modal:ver_filtro&id=${id}`,
  CREATE_PRODUCT_FILTER_CATEGORY: 'modal:criar_filtro',
  EDIT_PRODUCT_FILTER_CATEGORY: (id: ModalId) => `modal:editar_filtro&id=${id}`,
  // Product Category
  VIEW_PRODUCT_CATEGORY: (id: ModalId) => `modal:ver_categoria_de_produto&id=${id}`,
  CREATE_PRODUCT_CATEGORY: 'modal:criar_categoria_de_produto',
  EDIT_PRODUCT_CATEGORY: (id: ModalId) => `modal:editar_categoria_de_produto&id=${id}`,
  // Documents
  CREATE_NEW_DOCUMENT: 'modal:criar_documento',
  EDIT_DOCUMENT: (id: ModalId) => `modal:editar_documento&id=${id}`,
  // Documents Categories
  CREATE_NEW_DOCUMENT_CATEGORY: 'modal:criar_categoria_de_documento',
  EDIT_DOCUMENT_CATEGORY: (id: ModalId) => `modal:editar_categoria_de_documento&id=${id}`,
  // Product Orders
  READ_PRODUCT_ORDER: (id: ModalId) => `modal:ver_encomenda&id=${id}`,

  // Subscription Plans
  VIEW_SUBSCRIPTION_PLAN: (id: ModalId) => `modal:ver_modalidade&id=${id}`,
  CREATE_NEW_SUBSCRIPTION_PLAN: 'modal:criar_modalidade',
  EDIT_SUBSCRIPTION_PLAN: (id: ModalId) => `modal:editar_modalidade&id=${id}`,

  // Subscription Payments
  VIEW_SUBSCRIPTION_PAYMENT: (id: ModalId) => `modal:ver_pagamento_de_quota&id=${id}`,
  CREATE_SUBSCRIPTION_PAYMENT: 'modal:criar_pagamento_quotas',

};

// Routes

const backofficePrefix = '/backoffice';

export const APP_ROUTES = {
  home: '/',
  about: '/sobre',
  team: '/orgaos-sociais',
  partners: '/parcerias',
  contact: '/contactos',
  editorial: '/editorial',

  publication: '/publicacao',
  publications: '/publicacoes',
  publicationByCategoryId: '/publicacoes/categoria',

  manageAccount: '/gerir-conta',
  editProfile: '/gerir-conta/editar-perfil',
  changeUsername: '/gerir-conta/alterar-nome-de-utilizador',
  changeSubscriptionType: '/gerir-conta/alterar-categoria',
  changeEmail: '/gerir-conta/alterar-email',
  changePassword: '/gerir-conta/alterar-password',

  manageOrders: '/gerir-encomendas',

  becomeAMember: '/quero-ser-socio',
  verifyEmail: '/verificar-email',
  accountCreated: '/conta-criada',

  subscribe: '/subscrever',
  subscriptionStatus: '/subscricao',

  store: '/loja',

  product: '/produto',

  shoppingCart: '/carrinho',

  orderDetails: '/encomenda',

  termsAndConditions: '/termos-e-condicoes',

  privacyPolicy: '/politica-de-privacidade',

  requestPasswordReset: '/recuperar-acesso',
  resetPassword: '/criar-nova-password',

  subscriberArea: {
    root: '/area-do-socio',
    newsDetail: '/area-do-socio/noticia',
    documents: '/area-do-socio/documentos',
    documentsDetail: '/area-do-socio/documentos/categoria',
    subscriptionsHistory: '/area-do-socio/historico-de-subscricoes',
    dynamicPage: '/area-do-socio/pagina',
  },

  backoffice: {
    root: `${backofficePrefix}`,
    publications: {
      managePublications: `${backofficePrefix}/gerir-publicacoes`,
      manageCategories: `${backofficePrefix}/gerir-categorias-de-publicacoes`,
    },
    pages: {
      managePages: `${backofficePrefix}/gerir-paginas`,
    },
    newsletter: {
      manageNewsletter: `${backofficePrefix}/gerir-newsletter`,
    },
    subscriptions: {
      manageSubscriptions: `${backofficePrefix}/gerir-subscricoes`,
      manageSubscribers: `${backofficePrefix}/gerir-socios`,
    },
    subscriptionPlans: {
      manageSubscriptionPlans: `${backofficePrefix}/gerir-modalidades`,
    },
    users: {
      manageUsers: `${backofficePrefix}/gerir-utilizadores`,
      managePartners: `${backofficePrefix}/gerir-socios-users`,
    },
    orders: {
      manageProductOrders: `${backofficePrefix}/gerir-encomendas`,
    },
    store: {
      manageProductVariants: `${backofficePrefix}/gerir-variantes-de-produto`,
      manageProducts: `${backofficePrefix}/gerir-produtos`,
      manageProductFilters: `${backofficePrefix}/gerir-filtros-de-produto`,
      manageProductCategories: `${backofficePrefix}/gerir-categorias-de-produto`,
    },
    documents: {
      manageDocuments: `${backofficePrefix}/gerir-documentos`,
      manageDocumentCategories: `${backofficePrefix}/gerir-categorias-de-documentos`,
    },
  },
};

export const isLocal = () => APP_CONFIG.app.environment.toLocaleLowerCase() === Environments.LOCAL.toLocaleLowerCase();

/** Applies to google cloud staging environment */
export const isStaging = () => APP_CONFIG.app.environment.toLocaleLowerCase() === Environments.STAGING.toLocaleLowerCase();
